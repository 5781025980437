<template>
    <ContentField>

        <table class="table table-hover">
            <thead>
                <tr>
                    <th style="text-align: center; padding-left: 0vw;">玩家</th>
                    <th style="text-align: center; padding-right: 6vw;">天梯分</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="user in users" :key="user.id">
                    <td style="text-align: center;">
                        <div style="text-align: left; padding-left: 25vw;">
                            <img :src="user.photo" alt="" class="record-user-photo">
                             &nbsp; <!--//空格 -->
                            <span class="record-user-username">{{ user.username }}</span>
                        </div>
                    </td>
                    <td style="text-align: center; padding-right: 6vw;">{{ user.rating }}</td>
                </tr>
            </tbody>
        </table>
        <nav aria-label="...">
            <ul class="pagination" style="float: right;">
              <li class="page-item" @click="click_page(-2)">
                <a class="page-link" href="#">前一页</a>
              </li>
              <li :class="'page-item '+page.is_active" v-for="page in pages" :key="page.number" @click="click_page(page.number)">
                <a class="page-link" href="#">{{ page.number }}</a></li>
              <li class="page-item" @click="click_page(-1)">
                <a class="page-link" href="#">后一页</a>
              </li>
            </ul>
          </nav>
    </ContentField>
    </template>

    <script>
    import ContentField from "@/components/ContentField.vue";
    import { useStore } from "vuex";
    import $ from "jquery";
    import {ref} from"vue";
    export default{
        components:{
            ContentField
        },
        setup(){
            let users=ref([]);
            let current_page=1;
            let total_users=0;
            let pages=ref([]);
            const click_page=page=>{
                if(page===-2){
                    page=current_page-1;
                }else if(page===-1){
                    page=current_page+1;
                }
                let max_pages=parseInt(Math.ceil(total_users/10));
                if(page>=1&&page<=max_pages){
                    pull_page(page);
                }
            }
            const update_pages=()=>{
                let max_pages=parseInt(Math.ceil(total_users/10));
                let new_pages=[];
                for(let i=current_page-2;i<=current_page+2;i++){
                    if(i>=1&&i<=max_pages){
                        new_pages.push({
                            number:i,
                            is_active:i===current_page?"active":"",
                        });
                    }
                }
                pages.value=new_pages;
            }
            const store=useStore();
            const pull_page=page=>{
                current_page=page;
                $.ajax({
                    url:"https://www.ljy5960.cn/api/ranklist/getlist/",
                    data:{
                        page,
                    },
                    type:"get",
                    headers:{
                        Authorization:"Bearer "+store.state.user.token,
                    },
                    success(resp){
                        users.value=resp.users;
                        total_users=resp.users_count;
                        update_pages();
                    },
                    error(resp){
                        console.log(resp);
                    }
                })
            }
            pull_page(current_page);
            return{
                users,
                pages,
                click_page,
            }
        }
    }
    </script>
    <style scoped>
    img.record-user-photo{
        width: 5vh;
        border-radius: 50%;
    }
    </style>